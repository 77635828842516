import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import LoadingOverlay from 'src/components/applications/LoadingOverlay';
import { Breadcrumb, Card } from 'src/components/custom';
import { API_KEY } from 'src/constants/apiKey';
import {
  EMPTYSIZE,
  FINISHED_PRODUCT_PARAMS,
  SCREEN_ID,
  SHAPE,
  STATUS_CODE,
  SUGGEST_YOUKEN_TYPE,
} from 'src/constants/app';
import useQueryUrl from 'src/hooks/useQueryUrl';
import history from 'src/libs/history';
import { PATH as PATH_ORDER } from 'src/pages/order';
import { PATH as PATH_QUOTATION } from 'src/pages/quotation';
import useEstimatePrice from 'src/queries/useEstimatePrice';
import { productFormat } from 'src/utils/product';
import { formatSizes, getVisiableRollEyes } from 'src/utils/quotation';
import useForm from './useForm';

import { AxiosResponse } from 'axios';
import useProductInformation from 'src/queries/useProductInformation';
import quotation from 'src/repositories/quotation';
import LocalStorage from 'src/utils/LocalStorage';
import {
  checkIsProductFinished,
  convertFinishedSize,
  findOriginSize,
  getProductName,
  isImportedProductOnly,
  isMachiningMethodFromH2ToH5,
} from 'src/utils/helper';
import { I18n } from 'src/libs/i18n';
import CalculateResultTable from './CalculateResultTable';
import CategoriesList from './CategoriesList';
import CustomerInfo from './CustomerInfo';
import ErrorMessage from './ErrorMessage';
import InputOptional from './InputOptional';
import MachiningMethodList from './MachiningMethodList';
import ManuFacturingSize from './ManuFacturingSize';
import ProductList from './ProductList';
import SizeInput from './SizeInput';
import TrialNote from './TrialNote';
import CustomerProfile from './CustomerProfile';
import IndustryAndRegion from './IndustryAndRegion';

type Props = {
  isTrialMode?: boolean;
};

const Pricing: React.FC<Props> = ({ isTrialMode }) => {
  const [loading, setLoading] = useState(false);
  const [timerLoading, setTimerLoading] = useState<any>();
  const [product, setProduct] = useState('');
  const [estimateErrors, setEstimateErrors] = useState<string[]>([]);
  const [stockError, setStockError] = useState('');
  const [machiningMethod, setMachiningMethod] = useState('');
  const [manufacturingSizes, setManufacturingSizes] = useState<any>();
  const [productWidthData, setProductWidthData] = useState<any>();
  const [productLengthData, setProductLengthData] = useState<any>();
  const [sizes2, setSizes2] = useState<String>();
  const [sizes3, setSizes3] = useState<String>();
  const [productDetails, setProductDetails] = useState<any>();
  const [visibleRollEyes, setVisibleRollEyes] = useState(false);
  const [toleranceOptions, setToleranceOptions] = useState<any>({});
  const [dataCategoryPage, setDataCategoryPage] =
    useState<SearchParams.CategoryProductSearch>();
  const [labelError, setLabelError] =
    useState<Response.ManufacturingSize['item']>();

  const { t } = useTranslation();
  const client = useQueryClient();
  const params = useQueryUrl<SearchParams.EstimatePriceSearch>();
  const location = useLocation();

  const isProductFinished = checkIsProductFinished(
    params?.hinshL3Cd?.toString(),
  );

  const { formInfo } = useForm(
    labelError,
    isTrialMode,
    isImportedProductOnly({
      product: product,
      checkZ0: true,
      method: machiningMethod,
    }),
    isProductFinished,
  );
  const { handleSubmit, watch, getValues, setError, setValue, clearErrors } =
    formInfo;

  const state = location?.state as any;

  const apiPathCurrentCurrencyUnit = '/currency/current-unit';
  const apiPathCurrencyTransfers = '/currency/transfers';

  const { estimatePrice, estimateDetail, queryTaxes, queryProductDimension } =
    useEstimatePrice({
      setError,
      labelError,
      isTrialMode,
      estimateId: params.webMitrNo,
      shhnCd: productDetails?.shhnCd,
      hakudoShhnCd: productDetails?.hakudoShhnCd,
      api1ShhnCd: productDetails?.api1ShhnCd,
      api2ShhnCd: productDetails?.api2ShhnCd,
      snpo1: productDetails?.snpo1,
    });

  const estimatePriceData = estimatePrice.data?.data?.item;
  const dataReEstimate = estimateDetail?.data?.data?.item;
  const dataProductDimension = queryProductDimension?.data?.data?.item;

  const [lang, setLang] = useState(LocalStorage.lang);
  const prevLang = useRef(lang);

  const [formValue, setFormValue] = useState({});
  const [enableBackOrder, setEnableBackOrder] = useState(false);

  const listProductId = {
    hakudoShhnCd: productFormat.splitProductId(product)?.hakudoShhnCd,
    shhnCd: productFormat.splitProductId(product)?.shhnCd,
    api1ShhnCd: productFormat.splitProductId(product)?.api1ShhnCd,
    api2ShhnCd: productFormat.splitProductId(product)?.api2ShhnCd,
  };

  const { productsInfor } = useProductInformation(
    listProductId,
    isProductFinished,
    setEstimateErrors,
    setStockError,
  );

  useEffect(() => {
    setLang(LocalStorage.lang);
  }, [LocalStorage.lang]);

  /**
   * re-estimate from order history page.
   * re fill value of variety from detail order
   */
  useEffect(() => {
    if (dataReEstimate) {
      !getValues('variety') && setValue('variety', dataReEstimate?.hinshL3Cd);
    }
  }, [dataReEstimate]);

  // reload init url query data can't get detail re-estimate.
  useEffect(() => {
    if (estimateDetail.isError) {
      history.replace({
        search: qs.stringify({
          hinshL3Cd: watch('variety'),
          keijoL3Cd: watch('shape'),
        }),
      });
    }
  }, [estimateDetail.isError]);

  /**
   * fill form estimate by data product from category page.
   * set default value variety, shape and productId
   */
  useEffect(() => {
    const dataCategoryPage = state?.state?.product;
    const dataProductDetails = state?.productDetails;

    if (dataCategoryPage) {
      setValue('variety', dataCategoryPage?.hinshL3Cd);
      setDataCategoryPage(dataCategoryPage);
    }

    if (dataProductDetails) {
      setProductDetails(state?.productDetails);
    }
  }, [state?.state]);

  useEffect(() => {
    history.replace({
      search: qs.stringify(
        {
          hinshL3Cd: watch('variety'),
          keijoL3Cd: watch('shape'),
          webMitrNo: params.webMitrNo,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      ),
    });

    setVisibleRollEyes(
      getVisiableRollEyes(
        getValues('shape'),
        getValues('variety'),
        machiningMethod,
        product,
      ),
    );
  }, [
    watch('variety'),
    watch('shape'),
    machiningMethod,
    product,
    params.webMitrNo,
  ]);

  const handleSwapWidthAndLength = (width, length) => {
    // swap value of productWidth and productLength
    if (
      watch('shape') === SHAPE.PLATE &&
      width &&
      length &&
      Number(width) > Number(length)
    ) {
      [width, length] = [length, width];
      setValue('product_width', width);
      setValue('product_length', length);
    }
    return [width, length];
  };

  const onHandleCalculate = (val, reEstimateType?: string) => {
    const sizes1Data =
      findOriginSize(manufacturingSizes?.sizes, val?.product_thickness) ||
      val?.product_thickness;
    const sizes2Data =
      findOriginSize(productWidthData, val?.product_width) || sizes2;
    const sizes3Data =
      findOriginSize(productLengthData, val?.product_length) || sizes3;
    let productWidthVal = isMachiningMethodFromH2ToH5(machiningMethod)
      ? sizes2Data
      : val?.product_width;
    let productLengthVal = isMachiningMethodFromH2ToH5(machiningMethod)
      ? sizes3Data
      : val?.product_length;

    [productWidthVal, productLengthVal] = handleSwapWidthAndLength(
      productWidthVal,
      productLengthVal,
    );

    const widthSize =
      productWidthVal || (manufacturingSizes.size1Label && EMPTYSIZE);
    const lengthSize =
      productLengthVal || (manufacturingSizes.size2Label && EMPTYSIZE);
    const convertedSize = formatSizes({
      thickness: val?.converted_product_thickness || val?.product_thickness,
      width: val?.converted_product_width || widthSize,
      length: val?.converted_product_length || lengthSize,
    });
    const unitConvertedSize = formatSizes({
      thickness: manufacturingSizes?.unitSize1,
      width: manufacturingSizes?.size1Label && manufacturingSizes?.unitSize2,
      length: manufacturingSizes?.size2Label && manufacturingSizes?.unitSize3,
    });
    const inputSize = formatSizes({
      thickness: val?.product_thickness,
      width: widthSize,
      length: lengthSize,
    });
    const payload = {
      picName: val.customer_name,
      deliveryDestinationCd: val?.destination
        ? val?.destination.slice(0, 3)
        : val?.destinationItem?.rmOtokesaki?.otokesakiCd,
      hinshL3Cd: val?.variety,
      keijoL3Cd: val?.shape,
      hakudoShhnCd: productFormat.splitProductId(product)?.hakudoShhnCd,
      shhnCd: productFormat.splitProductId(product)?.shhnCd,
      api1ShhnCd: productFormat.splitProductId(product)?.api1ShhnCd,
      api2ShhnCd: productFormat.splitProductId(product)?.api2ShhnCd,
      hakudoShhnm: getProductName(val?.productItem, true),
      setdnKakoCd: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_SETDN_KAKO_CD
        : machiningMethod,
      setdnkako: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_SETDN_KAKO
        : val?.machiningItem?.label,
      snpoSize1: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_SNPO_SIZE1
        : val?.converted_product_thickness || val?.product_thickness,
      snpoSize2: val?.converted_product_width || productWidthVal,
      snpoSize3: val?.converted_product_length || productLengthVal,
      finishingSize: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_FINISHING_SIZE
        : convertedSize,
      finishingSizeText: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_FINISHING_SIZE_TEXT
        : val?.sizeItem?.format,
      numberOfMember: parseInt(val?.number_product),
      thicknessToleranceLabel:
        val?.machining_thickness &&
        t('pricing_page.machining_tolerance.plate_thickness'),
      thicknessToleranceCd: val?.machining_thickness,
      widthToleranceLabel:
        val?.machining_width && t('pricing_page.machining_tolerance.width'),
      widthToleranceCd: val?.machining_width,
      lengthToleranceLabel:
        val?.machining_length && t('pricing_page.machining_tolerance.length'),
      lengthToleranceCd: val?.machining_length,
      outsideDiaToleranceLabel:
        val?.machining_outside_diameter &&
        t('pricing_page.machining_tolerance.outside_diameter'),
      outsideDiaToleranceCd: val?.machining_outside_diameter,
      insideDiaToleranceLabel:
        val?.machining_inside_diameter &&
        t('pricing_page.machining_tolerance.inside_diameter'),
      insideDiaToleranceCd: val?.machining_inside_diameter,
      chamferType: val?.options_size_unspecified,
      chamferC: val?.options_size_c,
      cornerA: val?.corner_option_a,
      cornerB: val?.corner_option_b,
      cornerC: val?.corner_option_c,
      cornerD: val?.corner_option_d,
      specifiedRollingDCd: val?.roll_eyes,
      millSheetCd: val?.mill_sheets,
      orderNumber: val?.order_number,
      remarks: val?.order_notes,
      millingPatternCd: val?.chamferItem?.fraiseKakoPtn,
      millingPatternName: val?.chamferItem?.fraiseKakoPtnMeis,
      keijoInfo: {
        keijoL1Cd: manufacturingSizes?.keijoInfo?.keijoL1Cd,
        keijoL2Cd: manufacturingSizes?.keijoInfo?.keijoL2Cd,
        keijoL3Cd: manufacturingSizes?.keijoInfo?.keijoL3Cd,
      },
      sizes1: isProductFinished
        ? FINISHED_PRODUCT_PARAMS.DEFAULT_SIZE1
        : sizes1Data,
      sizes2: sizes2Data,
      sizes3: sizes3Data,
      suggestYoukenType: reEstimateType,
      webMitrNoReEstimation: reEstimateType
        ? estimatePriceData?.webMitrNo
        : undefined,
      zaikoTaniKbn: !manufacturingSizes?.sizeMustConvert
        ? convertFinishedSize({
            size: inputSize,
            unitSize: unitConvertedSize,
            manufacturingSizes: manufacturingSizes,
            product: product,
            method: machiningMethod,
            inputValue: val,
          })
        : undefined,
      inputSunpo: manufacturingSizes?.sizeMustConvert
        ? convertFinishedSize({
            size: inputSize,
            unitSize: unitConvertedSize,
            manufacturingSizes: manufacturingSizes,
            product: product,
            method: machiningMethod,
            inputValue: val,
          })
        : undefined,
      sunpoTaniKubun: manufacturingSizes?.originSize,
      convertFlg: manufacturingSizes?.sizeMustConvert,
      unitSizeConvert: unitConvertedSize,
      seihnJoho: val?.seihnJoho,
      regScreenID: isTrialMode ? SCREEN_ID.R1003 : SCREEN_ID.R1004,
    };
    const dataOmit: any = _.omitBy(
      payload,
      (v) => _.isUndefined(v) || _.isNull(v) || v === '' || v === 0,
    );
    estimatePrice.mutate(dataOmit);
  };

  const isFetchingProducts = useIsFetching([API_KEY.PRODUCTS]);
  const isFetchingMachinings = useIsFetching([API_KEY.MACHINING_METHOD]);
  const isFetchingSizes = useIsFetching([API_KEY.MANUFACTURING_SIZES]);
  const isMutatingConvertSizes = useIsMutating([API_KEY.CONVERT_SIZE]);

  useEffect(() => {
    if (
      isFetchingProducts > 0 ||
      isFetchingMachinings > 0 ||
      isFetchingSizes > 0 ||
      isMutatingConvertSizes > 0
    ) {
      clearTimeout(timerLoading);
      setLoading(true);
      return;
    }
    setTimerLoading(
      setTimeout(() => {
        setLoading(false);
      }, 200),
    );
    return () => {
      clearTimeout(timerLoading);
    };
  }, [
    isFetchingProducts,
    isFetchingMachinings,
    isFetchingSizes,
    isMutatingConvertSizes,
  ]);

  useEffect(() => {
    const errorCode = estimatePrice?.error?.response?.data?.code;
    if (errorCode) {
      switch (true) {
        case errorCode === STATUS_CODE.ESTIMATE_PRICE_ERROR:
          window.scrollTo({ top: 200, behavior: 'smooth' });
          return setEstimateErrors([t('message.E000079')]);
        case errorCode === STATUS_CODE.ESTIMATE_STOCK_ERROR:
          return setStockError(t('message.E000048'));
        case errorCode?.includes(','): {
          //code: "001,002,003" | 001 -> 025
          const errorMessages =
            errorCode
              ?.split(',')
              .map((code) => {
                if (code >= '001' && code <= '025') {
                  return I18n.t(`message.${code}`);
                } else {
                  return '';
                }
              })
              .filter((message) => message !== '') || [];

          if (errorMessages?.length === 0) {
            //if code > 025 => show E000079
            errorMessages?.push(I18n.t('message.E000079'));
          }
          window.scrollTo({ top: 200, behavior: 'smooth' });
          return setEstimateErrors(errorMessages);
        }
        case errorCode >= '001' && errorCode <= '025':
          return setEstimateErrors([I18n.t(`message.${errorCode}`)]);
        default: {
          window.scrollTo({ top: 200, behavior: 'smooth' });
          return setEstimateErrors([t('message.E000079')]);
        }
      }
    }
    setEstimateErrors([]);
    setStockError('');
  }, [estimatePrice?.error]);

  const checkCurrencyTransfer = async (data) => {
    const currentCurrencyRes = await client.fetchQuery<
      AxiosResponse<Response.CurrentCurrencyUnit>
    >({
      queryKey: [apiPathCurrentCurrencyUnit],
      queryFn: quotation.getCurrentCurrencyUnit,
    });

    const currencyTransfersRes = await client.fetchQuery<
      AxiosResponse<Response.CurrencyTransfers>
    >({
      queryKey: [apiPathCurrencyTransfers],
      queryFn: quotation.getCurrencyTransfers,
    });

    const currentCurrency = currentCurrencyRes?.data?.item;
    const currencyTransfers = currencyTransfersRes?.data?.items;
    const currency = data?.totalPrice?.split(' ')[1];

    if (currentCurrency && currencyTransfers) {
      const currentCurrencyTransfers = currencyTransfers
        ?.filter((item) => item.kawaseKbCdTo === currentCurrency?.currentKawase)
        ?.map((currency) => currency.kawaseKbCdFromSymbol)
        ?.concat(currentCurrency.currentKawaseSymbol);
      return currentCurrencyTransfers?.includes(currency);
    }
  };

  const onHandleOrder = async (data) => {
    if (await checkCurrencyTransfer(data)) {
      history.push(PATH_ORDER, { items: [data] });
    } else {
      window.scrollTo({ top: 200, behavior: 'smooth' });
      setEstimateErrors([t('message.E000079')]);
    }
  };

  useEffect(() => {
    if (dataProductDimension) {
      setValue('variety', dataProductDimension?.hinshL3Cd);
      setValue('shape', dataProductDimension?.keijoL3Cd);
    }
  }, [dataProductDimension]);

  const resetInputData = () => {
    setValue('product_thickness', '');
    clearErrors('product_thickness');
    setValue('product_width', '');
    clearErrors('product_width');
    setValue('product_length', '');
    clearErrors('product_length');
    setValue('number_product', '');
    clearErrors('number_product');
    setValue('seihnJoho', undefined);
    clearErrors('seihnJoho');
    setValue('machining_thickness', '');
    setValue('machining_width', '');
    setValue('machining_length', '');
    setValue('machining_outside_diameter', '');
    setValue('machining_inside_diameter', '');
    setValue('options_size_unspecified', '');
    setValue('options_size_c', '');
    setValue('corner_option_a', '');
    setValue('corner_option_b', '');
    setValue('corner_option_c', '');
    setValue('corner_option_d', '');
    setValue('roll_eyes', '');
    setValue('mill_sheets', '');
    setValue('order_number', '');
    setValue('order_notes', '');
    setToleranceOptions({});
  };

  useEffect(() => {
    if (estimatePriceData?.clearTolerance) {
      setValue('machining_thickness', '');
      setValue('machining_width', '');
      setValue('machining_length', '');
      setValue('machining_outside_diameter', '');
    }
  }, [estimatePriceData?.clearTolerance]);

  return (
    <div
      className={classNames('mb-30 mt-30 content_pricing', {
        content_pricing_trial: isTrialMode,
      })}
    >
      {!isTrialMode && <Breadcrumb />}
      <Card
        title={
          isTrialMode
            ? t('pricing_page.page_trial_title')
            : t('pricing_page.page_title')
        }
        className="content_pricing_card"
      >
        {_.size(estimateErrors) > 0 &&
          estimateErrors?.map((error, index) => (
            <ErrorMessage key={index} content={error} />
          ))}
        <div className="content_pricing_content">
          {loading && <LoadingOverlay />}
          <Form
            onFinish={handleSubmit((val) => {
              setFormValue(val);
              setEnableBackOrder(false);
              onHandleCalculate(val);
            })}
          >
            {!isTrialMode && (
              <CustomerInfo
                formInfo={formInfo}
                dataReEstimate={dataReEstimate}
                estimateId={params.webMitrNo}
                prevLang={prevLang?.current}
                lang={lang}
              />
            )}
            <CategoriesList
              formInfo={formInfo}
              dataReEstimate={dataReEstimate}
              dataCategoryPage={dataCategoryPage}
              prevLang={prevLang?.current}
              lang={lang}
              isProductFinished={isProductFinished}
            />
            <ProductList
              setProduct={setProduct}
              product={product}
              formInfo={formInfo}
              dataReEstimate={dataReEstimate}
              dataCategoryPage={dataCategoryPage}
              prevLang={prevLang?.current}
              lang={lang}
              dataProductDimension={dataProductDimension}
            />
            {!isProductFinished && (
              <MachiningMethodList
                product={product}
                isFetchingMachinings={isFetchingMachinings}
                machiningMethod={machiningMethod}
                setMachiningMethod={setMachiningMethod}
                formInfo={formInfo}
                dataReEstimate={dataReEstimate}
                dataProductDimension={dataProductDimension}
                resetInputData={resetInputData}
              />
            )}
            <ManuFacturingSize
              product={product}
              machiningMethod={machiningMethod}
              formInfo={formInfo}
              setLabelError={setLabelError}
              setEstimateErrors={setEstimateErrors}
              setSizes2={setSizes2}
              setSizes3={setSizes3}
              setStockError={setStockError}
              dataReEstimate={dataReEstimate}
              estimatePriceData={estimatePriceData}
              setManufacturingSizes={setManufacturingSizes}
              prevLang={prevLang?.current}
              lang={lang}
              shape={watch('shape')}
              dataProductDimension={dataProductDimension}
              isProductFinished={isProductFinished}
              productFinishedInfor={productsInfor}
              productLengthData={productLengthData}
              productWidthData={productWidthData}
              setProductLengthData={setProductLengthData}
              setProductWidthData={setProductWidthData}
              setToleranceOptions={setToleranceOptions}
              resetInputData={resetInputData}
            />
            {!isProductFinished && (
              <SizeInput
                machiningMethod={machiningMethod}
                formInfo={formInfo}
                dataReEstimate={dataReEstimate}
                toleranceOptions={toleranceOptions}
                product={product}
              />
            )}
            <InputOptional
              formInfo={formInfo}
              visibleRollEyes={visibleRollEyes}
              isProductFinished={isProductFinished}
            />
            {isTrialMode && <IndustryAndRegion formInfo={formInfo} />}
            <Divider className="mg-vertical-20" />
            <Row className="mb-10 mt-10" align="middle" justify="center">
              <Col xs={24} sm={16} md={8}>
                <Button
                  className="px-60 w-full"
                  type="primary"
                  htmlType="submit"
                  disabled={!isProductFinished && !machiningMethod}
                  loading={estimatePrice.isLoading}
                >
                  {t('pricing_page.calculate_button')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      {stockError && <ErrorMessage content={stockError} />}
      {estimatePriceData && (
        <React.Fragment>
          <CalculateResultTable
            estimatePriceData={estimatePriceData}
            queryTaxes={queryTaxes?.data?.data?.items}
          />
          {!enableBackOrder ? (
            (estimatePriceData?.hasCheap || estimatePriceData?.hasQuick) && (
              <div className="re_estimate_container">
                <Divider className="form_divider" />
                <Typography.Title
                  level={5}
                  className="re_estimate_condition_title"
                >
                  {t('pricing_page.re_estimate.note')}
                </Typography.Title>
                <Row className="re_estimate">
                  {estimatePriceData?.hasCheap && (
                    <Col className="re_estimate_condition">
                      <Typography.Title
                        level={5}
                        className="re_estimate_condition_title"
                      >
                        {t('pricing_page.re_estimate.cheaper')}
                      </Typography.Title>
                      <Button
                        type="primary"
                        onClick={() => {
                          setEnableBackOrder(true);
                          onHandleCalculate(
                            formValue,
                            SUGGEST_YOUKEN_TYPE.PRICE_LOW,
                          );
                        }}
                      >
                        {t('pricing_page.re_estimate.button')}
                      </Button>
                    </Col>
                  )}
                  {estimatePriceData?.hasQuick && (
                    <Col className="re_estimate_condition">
                      <Typography.Title
                        level={5}
                        className="re_estimate_condition_title"
                      >
                        {t('pricing_page.re_estimate.faster')}
                      </Typography.Title>
                      <Button
                        type="primary"
                        onClick={() => {
                          setEnableBackOrder(true);
                          onHandleCalculate(
                            formValue,
                            SUGGEST_YOUKEN_TYPE.FAST_DELIVERY,
                          );
                        }}
                      >
                        {t('pricing_page.re_estimate.button')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            )
          ) : (
            <div className="re_estimate_container">
              <Divider className="form_divider" />
              <Row className="initial_estimate">
                <Col xs={24} sm={16} md={7} lg={6}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setEnableBackOrder(false);
                      onHandleCalculate(
                        formValue,
                        SUGGEST_YOUKEN_TYPE.BACK_ORDER,
                      );
                    }}
                  >
                    {t('pricing_page.re_estimate.initial_estimate_result')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {isTrialMode && estimatePriceData && (
            <CustomerProfile estimatePriceData={estimatePriceData} />
          )}
          <Divider className="form_divider" />
          {!isTrialMode && (
            <Row align="middle" justify="center">
              <Col xs={24} md={14}>
                <Row align="middle" justify="space-between">
                  <Col xs={11} sm={16} md={10}>
                    <Button
                      className="mt-20 w-full"
                      type="primary"
                      htmlType="submit"
                      onClick={() => onHandleOrder(estimatePriceData)}
                      disabled={estimatePriceData?.totalMoneyExceedAllowed}
                    >
                      {t('pricing_page.estimate_results.confirm_order_button')}
                    </Button>
                  </Col>
                  <Col xs={11} sm={16} md={10}>
                    <Link to={PATH_QUOTATION}>
                      <Button
                        className="mt-20 w-full order_history_button"
                        type="primary"
                        htmlType="submit"
                      >
                        {t(
                          'pricing_page.estimate_results.estimate_history_button',
                        )}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
      {isTrialMode && <TrialNote />}
    </div>
  );
};

export default Pricing;
